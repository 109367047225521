<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col cols="12">
          <base-material-card
            color="primary"
            icon="mdi-select-compare"
            inline
            class="px-5 py-3"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Adicionar Novo Relatório
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Título"
                >
                  <v-text-field
                    label="Título"
                    v-model="titulo"
                    outlined
                    maxlength="100"
                    autofocus
                    clearable
                    placeholder="Informe um título para o relatório"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Título"
                >
                  <v-select
                    label="TUC"
                    v-model="tucsSelecionadas"
                    :items="tucsDisponiveis"
                    :error-messages="errors"
                    outlined
                    attach
                    chips
                    multiple
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col cols="6">
                <v-card tile>
                  <v-subheader> Base A </v-subheader>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-radio-group
                          v-model="baseA.tipo"
                          row
                        >
                          <v-radio
                            v-for="(base, i) in basesDisponiveis"
                            :key="i"
                            :label="base"
                            :value="base"
                          />
                        </v-radio-group>
                      </v-col>
                      <v-col cols="4">
                        <input-month
                          v-if="baseA.tipo === 'BDGD'"
                          label="Mês dos dados"
                          :selectedMonth="baseA.mesBdgd"
                          @monthSelected="
                            (mesBdgd) => (baseA.mesBdgd = mesBdgd)
                          "
                        />
                        <input-month
                          v-if="baseA.tipo === 'AIS'"
                          label="Mês dos dados"
                          :selectedMonth="baseA.mesAIS"
                          @monthSelected="(mesAIS) => (baseA.mesAIS = mesAIS)"
                        />
                        <input-month
                          v-if="baseA.tipo === 'Laudo'"
                          label="Mês dos dados"
                          :selectedMonth="baseA.mesLaudo"
                          @monthSelected="
                            (mesLaudo) => (baseA.mesLaudo = mesLaudo)
                          "
                        />
                      </v-col>
                      <v-col cols="4">
                        <select-bdgd-version
                          v-if="baseA.tipo === 'BDGD'"
                          label="Versão da BDGD"
                          required
                          :companyId="empresaId"
                          :month="baseA.mesBdgd"
                          :outlined="false"
                          @bdgdVersionSelected="
                            (bdgdVersaoId) =>
                              (baseA.bdgdVersaoId = bdgdVersaoId)
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card tile>
                  <v-subheader> Base B </v-subheader>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-radio-group
                          v-model="baseB.tipo"
                          row
                        >
                          <v-radio
                            v-for="(base, i) in basesDisponiveis"
                            :key="i"
                            :label="base"
                            :value="base"
                          />
                        </v-radio-group>
                      </v-col>
                      <v-col cols="4">
                        <input-month
                          v-if="baseB.tipo === 'BDGD'"
                          label="Mês dos dados"
                          :selectedMonth="baseB.mesBdgd"
                          @monthSelected="
                            (mesBdgd) => (baseB.mesBdgd = mesBdgd)
                          "
                        />
                        <input-month
                          v-if="baseB.tipo === 'AIS'"
                          label="Mês dos dados"
                          :selectedMonth="baseB.mesAIS"
                          @monthSelected="(mesAIS) => (baseB.mesAIS = mesAIS)"
                        />
                        <input-month
                          v-if="baseB.tipo === 'Laudo'"
                          label="Mês dos dados"
                          :selectedMonth="baseB.mesLaudo"
                          @monthSelected="
                            (mesLaudo) => (baseB.mesLaudo = mesLaudo)
                          "
                        />
                      </v-col>
                      <v-col cols="4">
                        <select-bdgd-version
                          v-if="baseB.tipo === 'BDGD'"
                          label="Versão da BDGD"
                          required
                          :companyId="empresaId"
                          :month="baseB.mesBdgd"
                          :outlined="false"
                          @bdgdVersionSelected="
                            (bdgdVersaoId) =>
                              (baseB.bdgdVersaoId = bdgdVersaoId)
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="salvarRelatorio"
            :disabled="!podeSalvarRelatorio"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ConsolidadorQuantidadesService from '@/services/ConsolidadorQuantidadesService';
import AisService from '@/services/AisService';
import LaudosService from '@/services/LaudosService';
import SelectBdgdVersion from '@/components/general/SelectBdgdVersion';
import InputMonth from '@/components/general/InputMonth';
import InputDate from '@/components/general/InputDate';
import routes from '@/store/modules/routes';

const tucs = [
  125, 135, 160, 190, 210, 255, 295, 330, 340, 345, 395, 545, 565, 570, 575, 580
];

export default {
  components: {
    SelectBdgdVersion,
    InputMonth,
    InputDate
  },
  data: () => ({
    loading: false,
    titulo: null,
    tucsDisponiveis: tucs,
    tucsSelecionadas: tucs,
    basesDisponiveis: ['BDGD', 'AIS', 'Laudo'],
    baseA: {
      tipo: 'BDGD',
      mesBdgd: null,
      mesAIS: null,
      mesLaudo: null,
      bdgdVersaoId: null
    },
    baseB: {
      tipo: 'BDGD',
      mesBdgd: null,
      mesAIS: null,
      mesLaudo: null,
      bdgdVersaoId: null
    }
  }),
  mounted() {
    this.setDataBaseBDGD();
    this.getUltimaDataBaseAIS();
    this.getUltimaDataBaseLaudo();
  },
  computed: {
    empresaId() {
      return this.$store.getters.getCompanyId;
    },
    bdgdVersao() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    baseAValida() {
      const { tipo, mesBdgd, mesAIS, mesLaudo, bdgdVersaoId } = this.baseA;
      return !!(
        (tipo === 'BDGD' && mesBdgd && bdgdVersaoId) ||
        (tipo === 'AIS' && mesAIS) ||
        (tipo === 'Laudo' && mesLaudo)
      );
    },
    baseBValida() {
      const { tipo, mesBdgd, mesAIS, mesLaudo, bdgdVersaoId } = this.baseB;
      return !!(
        (tipo === 'BDGD' && mesBdgd && bdgdVersaoId) ||
        (tipo === 'AIS' && mesAIS) ||
        (tipo === 'Laudo' && mesLaudo)
      );
    },
    podeSalvarRelatorio() {
      return !!(
        this.titulo &&
        this.tucsSelecionadas.length > 0 &&
        this.baseA.tipo &&
        this.baseB.tipo &&
        this.baseAValida &&
        this.baseBValida
      );
    }
  },
  methods: {
    setDataBaseBDGD() {
      if (!this.bdgdVersao) return;
      const mesBdgd = dayjs(this.bdgdVersao.data_registro).format('YYYY-MM');
      this.baseA.mesBdgd = mesBdgd;
      this.baseB.mesBdgd = mesBdgd;
    },
    getUltimaDataBaseAIS() {
      AisService.getUltimaDataBase(this.empresaId)
        .then(({ data }) => {
          const [year, month] = data.split('-');
          const mesAIS = data ? `${year}-${month}` : null;
          this.baseA.mesAIS = mesAIS;
          this.baseB.mesAIS = mesAIS;
        })
        .catch((err) => {
          this.$toast.error('Erro ao buscar a última data base do AIS.', '', {
            position: 'topRight'
          });
          console.error('Erro:', err);
        });
    },
    getUltimaDataBaseLaudo() {
      LaudosService.getUltimaDataBase(this.empresaId)
        .then(({ data }) => {
          const [year, month] = data.split('-');
          const mesLaudo = data ? `${year}-${month}` : null;
          this.baseA.mesLaudo = mesLaudo;
          this.baseB.mesLaudo = mesLaudo;
        })
        .catch((err) => {
          this.$toast.error('Erro ao buscar a última data base do Laudo.', '', {
            position: 'topRight'
          });
          console.error('Erro:', err);
        });
    },
    async salvarRelatorio() {
      this.loading = true;

      try {
        const relatorio = {
          titulo: this.titulo,
          tucs_selecionadas: this.tucsSelecionadas,
          base_a: this.baseA,
          base_b: this.baseB
        };
        await ConsolidadorQuantidadesService.salvarRelatorio(relatorio);
        this.$toast.success('Relatório cadastrado com sucesso.', '', {
          position: 'topRight'
        });

        this.$router.push({
          name: routes.state.appRoutes['CONSOLIDADOR_QUANTIDADES_HISTORICO']
        });
      } catch (e) {
        this.$toast.error('Ocorreu um erro ao cadastrar o relatório.', '', {
          position: 'topRight'
        });
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
