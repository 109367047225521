<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
  >
    <v-select
      no-gutters
      v-model="bdgdVersionId"
      :outlined="outlined"
      item-value="id"
      item-text="versao"
      :items="bdgdVersions"
      :label="label"
      :error-messages="errors"
    />
  </validation-provider>
</template>

<script>
import BdgdVersionsService from '@/services/BdgdVersionsService';

export default {
  name: 'SelectBdgdVersion',
  props: {
    label: {
      type: String,
      default: 'Versão da BDGD'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    companyId: {
      type: Number,
      default: 0
    },
    month: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bdgdVersionId: null,
      bdgdVersions: []
    };
  },
  mounted() {
    this.getBdgdVersions();
  },
  computed: {
    data() {
      return this.month ? `${this.month}-01` : null;
    },
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    rules() {
      let rules = '';
      if (this.required) {
        rules += 'required';
      }
      return rules;
    }
  },
  watch: {
    companyId() {
      this.getBdgdVersions();
    },
    month() {
      this.getBdgdVersions();
    },
    bdgdVersionId() {
      this.$emit('bdgdVersionSelected', this.bdgdVersionId);
    },
    bdgdVersions() {
      this.getLastBdgdVersion();
    }
  },
  methods: {
    getBdgdVersions() {
      if (!(this.companyId && this.data)) {
        return;
      }
      BdgdVersionsService.getBdgdVersions(this.companyId, this.data)
        .then((versions) => {
          this.bdgdVersions = versions;
          this.bdgdVersionId = null;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar as versões da BDGD.', '', {
            position: 'topRight'
          });
        });
    },
    getLastBdgdVersion() {
      if (!(this.companyId && this.data)) {
        return;
      }
      if (this.userSelectedCompanyBdgdVersion) {
        this.bdgdVersionId = this.userSelectedCompanyBdgdVersion.id;
        return this.bdgdVersionId;
      }
      BdgdVersionsService.getBdgdVersion(this.companyId, this.data)
        .then((lastBdgdVersion) => {
          if (lastBdgdVersion) {
            let bdgdVersion = this.bdgdVersions
              .filter((version) => version.versao === lastBdgdVersion)
              .shift();
            if (bdgdVersion && bdgdVersion.id) {
              this.bdgdVersionId = bdgdVersion.id;
            }
          }
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao busca a última versão da BDGD.', '', {
            position: 'topRight'
          });
        });
    }
  }
};
</script>
