<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider
        v-slot="{ errors }"
        :rules="rules"
      >
        <v-text-field
          v-model="formatedData"
          :error-messages="errors"
          v-mask="'##/##/####'"
          :label="label"
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
          @blur="data = parseDate(formatedData)"
          class="pt-0"
          clearable
        />
      </validation-provider>
    </template>
    <v-date-picker
      v-model="data"
      @input="
        menu = false;
        formatedData = formatDate(data);
      "
      locale="pt-br"
    />
  </v-menu>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'InputDate',
  props: {
    label: {
      type: String,
      default: 'Data'
    },
    selectedDate: {
      type: String,
      default: null
    },
    rules: {
      type: Object,
      default() {
        return {
          min: 10
        };
      }
    }
  },
  data() {
    return {
      data: null,
      formatedData: null,
      menu: false
    };
  },
  mounted() {
    if (this.selectedDate) {
      this.data = this.selectedDate;
      this.formatedData = this.formatDate(this.selectedDate);
    }
  },
  watch: {
    data() {
      this.$emit('dataSelected', this.data);
    },
    selectedDate() {
      if (this.selectedDate) {
        this.data = this.selectedDate;
        this.formatedData = this.formatDate(this.selectedDate);
      } else {
        this.data = null;
        this.formatedData = null;
      }
    }
  },
  methods: {
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    formatDate(date) {
      if (date.length > 10) {
        date = date.substr(0, 10);
      }
      return dayjs(date).format('DD/MM/YYYY');
    }
  }
};
</script>
