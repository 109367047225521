import APIService from './APIService';

export default {
  getRelatorios() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/consolidador-quantidades/get-relatorios')
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },
  salvarRelatorio(postData) {
    return APIService.apiCall().post(
      '/consolidador-quantidades/salvar-relatorio',
      JSON.stringify(postData)
    );
  },
  deletarRelatorio(id) {
    return APIService.apiCall().delete(
      `/consolidador-quantidades/${id}`
    );
  },
  executarRelatorio(id) {
    return APIService.apiCall().patch(`/consolidador-quantidades/executar/${id}`);
  },
  baixarResultado(id, config = {}) {
    return APIService.apiCall().get(
      `/consolidador-quantidades/baixar-resultado/${id}`,
      { responseType: 'blob', ...config }
    );
  }
};
